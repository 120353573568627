exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-oferta-qr-tsx": () => import("./../../../src/pages/oferta-qr.tsx" /* webpackChunkName: "component---src-pages-oferta-qr-tsx" */),
  "component---src-pages-oferta-schemat-tsx": () => import("./../../../src/pages/oferta-schemat.tsx" /* webpackChunkName: "component---src-pages-oferta-schemat-tsx" */),
  "component---src-pages-oferta-szczecin-tsx": () => import("./../../../src/pages/oferta-szczecin.tsx" /* webpackChunkName: "component---src-pages-oferta-szczecin-tsx" */),
  "component---src-pages-oferta-tsx": () => import("./../../../src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-qr-szczecin-tsx": () => import("./../../../src/pages/qr-szczecin.tsx" /* webpackChunkName: "component---src-pages-qr-szczecin-tsx" */),
  "component---src-pages-qr-tsx": () => import("./../../../src/pages/qr.tsx" /* webpackChunkName: "component---src-pages-qr-tsx" */),
  "component---src-pages-recommendations-tsx": () => import("./../../../src/pages/recommendations.tsx" /* webpackChunkName: "component---src-pages-recommendations-tsx" */),
  "component---src-pages-strona-w-budowie-tsx": () => import("./../../../src/pages/strona-w-budowie.tsx" /* webpackChunkName: "component---src-pages-strona-w-budowie-tsx" */),
  "component---src-pages-zalecenia-tsx": () => import("./../../../src/pages/zalecenia.tsx" /* webpackChunkName: "component---src-pages-zalecenia-tsx" */)
}

